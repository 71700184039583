<template>
	<v-container fluid style="max-width: 1200px">
		<div
			class="mx-2 my-3"
			:class="{ 'd-flex': true, 'justify-space-between': true, 'flex-wrap': true, 'flex-column': $vuetify.breakpoint.smAndDown }"
		>
			<span class="text-h3">{{ course.name }}</span>
			<div class="d-flex justify-space-around">
				<CourseJoinButton :loading-parent="loading" :course="course" />
			</div>
		</div>

		<v-divider class="my-2" />

		<v-chip-group v-model="chipSelected" column color="sidebarBackground" active-class="sidebarBackground" class="my-3">
			<v-chip value="CourseExplorer" :disabled="chipSelected == 'CourseExplorer'">
				<v-btn text :to="{ name: 'CourseExplorer', params: { id: course.id } }" block rounded plain class="px-0">
					<v-icon class="mr-3"> mdi-folder </v-icon>
					{{ $t('courses.explorer') }}
				</v-btn>
			</v-chip>
			<v-chip value="CourseFeedPosts" :disabled="chipSelected == 'CourseFeedPosts'">
				<v-btn text :to="{ name: 'CourseFeedPosts', params: { id: course.id } }" block rounded plain class="px-0">
					<v-icon class="mr-3"> mdi-rss </v-icon>
					{{ $t('nav.newsfeed') }}
				</v-btn>
			</v-chip>
			<v-chip value="CourseDetails" :disabled="chipSelected == 'CourseDetails'">
				<v-btn text :to="{ name: 'CourseDetails', params: { id: course.id } }" block rounded plain class="px-0">
					<v-icon class="mr-3"> mdi-details </v-icon>
					{{ $t('courses.details') }}
				</v-btn>
			</v-chip>
		</v-chip-group>

		<router-view />
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.course.name,
		}
	},
	data() {
		return {
			loading: true,
			chipSelected: 'NewsfeedPosts',
		}
	},
	components: {
		CourseJoinButton: () => import('@/components/courses/CourseJoinButton.vue'),
	},
	computed: {
		...mapGetters({
			course: 'courses/course',
		}),
	},
	watch: {
		'$route.params.id'(id) {
			this.loading = true
			this.fetchCourse(id)
				.then(({ success }) => {
					if (!success) {
						this.$router.replace({ name: 'Error404', params: { resource: 'course' } })
					}
				})
				.then(() => {
					this.loading = false
				})
		},
	},
	created() {
		this.chipSelected = this.$route.name
		this.fetchCourse(this.$route.params.id)
			.then(({ success }) => {
				if (!success) {
					this.$router.replace({ name: 'Error404', params: { resource: 'course' } })
				}
			})
			.then(() => {
				this.loading = false
			})
	},
	methods: {
		...mapActions('courses', ['fetchCourse']),
	},
}
</script>

<style scoped>
.v-chip--disabled {
	opacity: 1;
}
</style>
